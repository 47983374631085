import React from "react";
import BlogMain from "./components/blog-main/BlogMain";

const Blog: React.FC = () => {
  return (
    <>
      <BlogMain />
    </>
  );
};

export default Blog;
